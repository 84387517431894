import './hero.scss';

const Hero = ({ brand, service, slogan, image, booking, phone }) => (
  <section className="hero">
    <div className="container">
      <div className="container__inner">
        <div className="mobile-image">
          <img src={image} alt={service} />
        </div>

        <div className="hero__content">
          <h1>
            {brand}
            <br />
            {service}
          </h1>
          <p>{slogan}</p>

          <div className="hero__content--links">
            <a href={booking} className="red-button">
              Book now
            </a>
            <span>
              or call us <a href={`tel:+1${phone}`}>{phone}</a>
            </span>
          </div>
        </div>

        <div
          className="hero__image"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
      </div>
    </div>
  </section>
);

export default Hero;
