import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import PageTemplate from '../pages/PageTemplate';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import AccessibilityStatement from '../pages/AccessibilityStatement';
import TermsOfUse from '../pages/TermsOfUse';
import PageNotFound from '../pages/404';
import { AppContext } from './AppContext';

const AppRouter = () => {
  const {
    context: { services, areas, settings },
  } = useContext(AppContext);

  if (!areas || !settings || !services) return <></>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={':location'}>
          <Route index element={<Navigate replace to={settings?.default_service?.post_name || services[0].slug} />} />
          <Route path=":service" element={<PageTemplate />} />
        </Route>

        <Route
          index
          element={<Home cities={areas} services={services} logo={settings?.logo} brand={settings?.brand} />}
        />

        <Route
          path="privacy-policy"
          element={
            <PrivacyPolicy
              content={settings?.policy}
              social={settings?.social}
              logo={settings.logo}
              logoFooter={settings.logo_footer}
            />
          }
        />
        <Route
          path="accessibility-statement"
          element={
            <AccessibilityStatement
              content={settings?.accessibility}
              social={settings?.social}
              logo={settings.logo}
              logoFooter={settings.logo_footer}
            />
          }
        />
        <Route
          path="terms-of-use"
          element={
            <TermsOfUse
              content={settings?.tos}
              social={settings?.social}
              logo={settings.logo}
              logoFooter={settings.logo_footer}
            />
          }
        />

        <Route path="404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
