import { Layout, Seo, ContentBlock } from '../components';

const AccessibilityStatement = ({ content: { title, text }, social, logo, logoFooter }) => {
  return (
    <Layout pageId="accessibility-statement" social={social} logo={logo} logoFooter={logoFooter}>
      <Seo />
      <section>
        <ContentBlock title={title} content={text} />
      </section>
    </Layout>
  );
};

export default AccessibilityStatement;
