import { Link } from 'react-router-dom';

import styles from './home.module.scss';

const HomeScreen = ({ locations, logo, brand }) => (
  <main className={styles.home}>
    <div className={styles.container}>
      <div className={styles.inner}>
        <h1>
          <img src={logo} alt={brand} className={styles.logo} />
        </h1>
        <h2 className={styles.header}>We work in these locations:</h2>
        <ul className={styles.locations}>
          {locations.map(({ id, slug, title }) => (
            <li key={id} className={styles.location}>
              <Link to={slug} className={styles.link}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </main>
);

export default HomeScreen;
