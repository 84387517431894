import { useContext } from 'react';
import { Link } from 'react-router-dom';

import './footer.scss';
import { AppContext } from '../AppContext';

const Footer = ({ location, phone }) => {
  const {
    context: {
      settings: { logo_footer: logoFooter, logo, brand, email, accessibility, policy, tos },
    },
  } = useContext(AppContext);

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="container__inner">
          {(accessibility.title || policy.title || tos.title) && (
            <div className="links">
              {policy.title && <Link to="/privacy-policy">Privacy Policy</Link>}
              {tos.title && <Link to="/terms-of-use">Terms Of Use</Link>}
              {accessibility.title && <Link to="/accessibility-statement">Accessibility Statement</Link>}
            </div>
          )}
          <div className="footer__wrapper">
            <Link to={`/${location || ''}`} className="page-footer__logo">
              <img src={logoFooter || logo} alt={brand} />
            </Link>
            <div className="footer__info">
              <b className="footer__contact-us">Contact us</b>
              {phone && (
                <div>
                  Phone:{' '}
                  <a href={`tel:+1${phone}`} className="phone">
                    {phone}
                  </a>
                </div>
              )}
              <div>
                Email:{' '}
                <a href={`mailto:${email}`} className="email">
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
