import Slider from 'react-slick';

import './reviews.scss';

const slider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ reviews }) => (
  <section className="reviews">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Reviews</h2>
      </div>

      <Slider className="reviews__list" {...slider}>
        {reviews.map((review, index) => (
          <ReviewCard {...{ review }} key="index" />
        ))}
      </Slider>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review: { name, text } }) => {
  const type = ['', 'dark', 'contrast'][~~(Math.random() * 3)];

  return (
    <div className={`reviews__list--card ${type}`}>
      <div className="reviews__list--card__header">
        <div className="review__stars">
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
        </div>
      </div>
      <div className="reviews__list--card__info">
        <div className="review__name">{`${name}:`}</div>
      </div>
      <div>« {text} »</div>
    </div>
  );
};
