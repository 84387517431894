import { Link } from 'react-router-dom';

import './services.scss';

const Services = ({ services, location }) => (
  <section className="services">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Appliances we repair</h2>
      </div>
      <div className="services__cards">
        {services
          .sort(({ title: a }, { title: b }) => {
            if (a < b) return -1;
            return 1;
          })
          .map((service) => (
            <ServiceCard {...{ service, location }} key={service.id} />
          ))}
      </div>
    </div>
  </section>
);

export default Services;

const ServiceCard = ({ service: { slug, icon, title }, location }) => {
  const type = ['', 'yellow', 'blue', 'dark'][~~(Math.random() * 4)];
  return (
    <Link to={`/${location}/${slug}`} className={`services__list--card ${type}`}>
      <div className="icon">
        <img src={icon} alt={title} />
      </div>

      <h4 className="services__card-title">
        <img src="../assets/images/link_arrow.svg" alt="arrow" className="arrow" />
        {title}
      </h4>
    </Link>
  );
};
