import { Spinner } from '../components';

import { HomeScreen } from '../sections';

const Home = ({ cities, logo, brand }) => {
  if (!cities) return <Spinner />;

  return <HomeScreen locations={cities} logo={logo} brand={brand} />;
};

export default Home;
