import { useNavigate } from 'react-router-dom';

import './contentBlock.scss';

const ContentBlock = ({ title, content }) => {
  const navigate = useNavigate();

  return (
    <div className="content-wrapper">
      <div className="container">
        <h1>{title}</h1>
        <div className="content__text" dangerouslySetInnerHTML={{ __html: content }} />

        <div className="red-button" onClick={() => navigate(-1)}>
          Go Back
        </div>
      </div>
    </div>
  );
};

export default ContentBlock;
