import './areas.scss';

const Areas = ({ areas }) => (
  <section className="areas">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">{'Areas We Serve'}</h2>
      </div>

      <ul>
        {areas
          .sort(({ name: a }, { name: b }) => {
            if (a < b) return -1;
            return 1;
          })
          .map(({ location }, index) => (
            <li key={index}>{location}</li>
          ))}
      </ul>
    </div>
  </section>
);

export default Areas;
