import './about.scss';

const About = ({ service, content, image }) => {
  return (
    <section className="about">
      <div className="container">
        <div className="container__inner">
          <div className="about__content">
            <h2 className="section-title">{`${service} Professionals`}</h2>

            <div className="about__text" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
