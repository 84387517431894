import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../AppContext';

import styles from './header.module.scss';

const Header = ({ phone, booking, location }) => {
  const {
    context: {
      settings: { logo, brand },
    },
  } = useContext(AppContext);

  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`${styles._} ${shadow && styles._shadow}`}>
      <div className={styles.container}>
        <div className={styles.links_mobile}>
          {phone && (
            <a href={`tel:+1${phone}`} className={`${styles.phone} ${styles.phone_mobile}`}>
              {phone}
            </a>
          )}
        </div>
        <div className={styles.inner}>
          <Link to={`/${location || ''}`} className={styles.logo}>
            <img src={logo} alt={brand} className={styles['logo-image']} />
          </Link>

          <div className={styles.links}>
            {phone && (
              <a href={`tel:+1${phone}`} className={styles.phone}>
                {phone}
              </a>
            )}
            {booking && (
              <a href={booking} rel="noReferrer" target="_blank" className={`${styles.booking} button`}>
                Book now
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
