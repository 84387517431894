import { useContext } from 'react';
import { AppContext } from '../../components/AppContext';
import './whyWe.scss';

const WhyWe = () => {
  const {
    context: {
      settings: { pros },
    },
  } = useContext(AppContext);
  return (
    <section className="why-we">
      <div className="container">
        <div className="container__inner">
          <h2 className="section-title">Why Choose Us?</h2>
          <div className="why-we__grid">
            {pros.map(({ icon, title, text }, index) => {
              const type = ['yellow', '', 'dark', 'blue'][index];
              return (
                <div key={title + index} className={`why-we__card ${type}`}>
                  <div className="icon">
                    <img src={icon} alt={title} />
                  </div>
                  <h4>{title}</h4>
                  <p>{text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWe;
