import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './components/App';

import './styles/main.scss';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
