import { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Layout } from '../components';

import { Hero, WhyWe, About, Services, Reviews, Areas, Brands, CallToAction } from '../sections';
import { AppContext } from '../components/AppContext';

const PageTemplate = () => {
  const {
    context: {
      areas,
      settings: {
        email,
        brand,
        slogan,
        brands,
        social,
        reviews,
        pros,
        logo,
        logo_footer: logoFooter,
        default_service: { ID: defaultServiceId },
      },
    },
  } = useContext(AppContext);

  const { location: currentLocation, service: currentService } = useParams();
  // Area
  const { phone, booking, services, locations, title: location } = areas.find(({ slug }) => slug === currentLocation);
  // Service
  const {
    id,
    mainImage,
    aboutImage,
    title: service,
    content: aboutContent,
  } = services.find(({ slug }) => slug === currentService);

  if (!id) return <Navigate replace to="/404" />;

  return (
    <Layout
      id={currentService}
      brand={brand}
      service={service}
      email={email}
      phone={phone}
      booking={booking}
      location={location}
      slug={currentLocation}
      social={social}
      logo={logo}
      logoFooter={logoFooter}
    >
      <Hero brand={brand} service={service} slogan={slogan} image={mainImage} booking={booking} phone={phone} />
      {pros?.length && <WhyWe data={pros} />}
      <About service={service} content={aboutContent} image={aboutImage} />
      {services?.length && (
        <Services services={services.filter(({ id }) => id !== defaultServiceId)} location={currentLocation} />
      )}
      <CallToAction phone={phone} booking={booking} />
      {locations?.length && <Areas areas={locations} />}
      {brands?.length && <Brands brands={brands} />}
      {reviews?.length && <Reviews reviews={reviews} />}
    </Layout>
  );
};

export default PageTemplate;
